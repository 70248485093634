.m-btn{
  @apply bg-teal text-white font-Spartan font-medium text-18 px-6 py-3 rounded-lg;
}
.cart-btn{
  @apply bg-teal text-white font-Spartan font-medium text-18 px-6 py-3 rounded-r-lg;
}

 .m-btn-hero{
  @apply inline-flex gap-4 items-center;

 }
 .m-btn-hero:hover{
  @apply transition-[0.4s];
  background-image: linear-gradient(-90deg, #67A2D4 0%, #073565 100%) ;
 }

 .abt-btn-hero{
  @apply text-white font-Spartan font-medium text-18 px-6 py-3 rounded-lg;
  background-color: #38b2ac;
}
 .abt-btn-hero:hover{
  @apply transition-[0.4s];
  background-image: linear-gradient(-90deg, #67A2D4 0%, #073565 100%) ;
 }
 .cart-btn{
  @apply bg-emerald-500 text-white font-Spartan font-medium text-18 px-6 py-3 rounded-tr-lg ;
}


 .cart-btn-hero{
  @apply inline-flex rounded-r-lg gap-4 items-center bg-indigo-900;

 }
 .cart-btn-hero:hover{
  @apply transition-[0.4s];
  background-image: linear-gradient(-90deg,  #67A2D4 0%, #073565  100%) ;
 }

