.course-details-seo .pc-dp-title {
  @apply text-center border-b border-b-gray-300 pb-7 text-48;
}
.course-sidebar {
  @apply h-full w-1/4 z-50 fixed overflow-y-auto bg-white top-0 left-0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
}

.course-seo-wrapper {
  @apply flex  relative;
}

.course-details {
  @apply py-8;
}

.corse-video {
  @apply my-8;
}
.corse-video iframe {
  @apply w-full;
}

.corse-forms-col {
  @apply grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-7;
}
.Course-input,
.corse-textarea {
  box-shadow: 0px 10px 50px 0px rgba(26, 46, 85, 0.1);
  @apply w-full bg-white rounded-lg py-6 px-4 mb-8 outline-none text-18 focus-visible:border-solid focus-visible:border focus-visible:border-navyblue;
}
.corse-forms .pc-dp-title {
  @apply text-left pb-4 border-none mb-1;
}
.corse-forms {
  @apply mt-20;
}
.corse-side-btn {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  @apply h-14 w-8 rounded-md bg-white flex justify-center items-center;
}
.arrow-sidebar {
  @apply w-7 h-7 rotate-180;
}
.corse-side-btn {
  @apply top-[198px] left-[23.5%] cursor-pointer;
}
.course-seo-wrapper.deactivate .corse-side-btn {
  @apply left-0;
}
.course-seo-wrapper.deactivate .arrow-sidebar {
  @apply rotate-0;
}
