.checkout-wrapper {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-8 my-8;
}

.coupon-wrapper {
  @apply grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-8 my-8;
}

.checkout-ship-radio .input-wrapper-radio {
  @apply mb-5;
}
.checkout-box {
  @apply bg-[#F5F5F5] p-8 mb-4 sm:mb-8;
}

.checkout-order-wrapper {
  @apply grid grid-cols-2 gap-8;
}
.checkout-logo-wrapper {
  @apply flex gap-6 items-center flex-col sm:flex-row;
}
.checkout-order-wrapper .checkout-order-col:last-child {
  @apply text-right sm:text-left;
}
.checkout-order-prdname {
  @apply text-black font-bold text-base sm:text-18 capitalize mb-5;
}
.checkout-order-name {
  @apply text-black font-normal text-base sm:text-18 mb-4;
}
.checkout-order-border {
  @apply border-b border-b-[#1212124D] border-dashed mb-5;
}
.checkout-order-pricetax {
  @apply text-black font-normal text-base sm:text-18 mb-4;
}
.checkout-total-price {
  @apply text-black font-bold text-18 sm:text-24 text-right sm:text-left;
}
.paypal_img {
  @apply w-52 h-16 object-cover mb-4;
}
.checkbox-details {
  @apply text-base sm:text-18 text-black font-normal mb-4;
}
.checkout-text-change {
  @apply text-pink;
}
.checkout-term-condition .input-wrapper-radio {
  @apply mb-0;
}
.checkout-term-condition {
  @apply mb-4 md:mb-9;
}


.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.check-container {
  width: 6.25rem;
  /* height: 7.5rem; */
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}
.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.check-container .check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}


.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #49da83, transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

.check-container .check-shadow.err{
  background: radial-gradient(closest-side, #ff6f71, transparent);
}

.coupon-input:focus-visible,
.coupon-input:focus,
.coupon-input:hover,
.coupon-input:active{
border: none;
outline: none;
}


.coupon-card{
  position: relative;
}

.coupon-card p{
  font-size: 15px;


}
.coupon-row{
  display: flex;
  align-items: center;
  width: fit-content;

}

.coupon_code{
  border: 1px dashed #1212124D;
}

.coupon_code:disabled{
  opacity: 0.7;
}

.tick_{
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius: 50%;
    background: green;
    color: #fff;
}

#cpnCode{
  border: 1px dashed #1212124D;
  padding: 10px 20px;
  /* border-right: 0; */

}
#cpnBtn{
  padding: 10px 20px;
  border-radius: 0px;
  border: none;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}
@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}



.circle,
.circle-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-anim 400ms ease;
  background: #f87171;	
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}

.error::before,
.error::after {
  content: "";
  display: block;
  height: 4px;
  background: #f87171;
  position: absolute;
}

.error::before {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(50deg);
}

.error::after {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(-50deg);
}