.p-course-banner{
    @apply bg-[#f5f9fa] text-center py-32;
}
.p-course-title{
    @apply text-black text-6xl font-semibold mb-4;
}
.p-couse-bradcom-wrapper{
    @apply flex justify-center gap-4 items-center flex-wrap;
}
.p-bradcom-item{
    @apply text-24 font-medium font-Spartan;
}

.pc-filter-section{
    @apply flex justify-between gap-5 flex-wrap items-center mt-2;
}
.pc-filter-title{
    @apply text-black font-medium text-28 ;
}
.pc-filter-title span{
    @apply text-teal;
}
.pc-search{
    @apply w-full  sm:w-[400px] relative;
}
.pc-search-input{
    @apply w-full text-black text-xl p-4 outline-none bg-lightgray rounded-md;
}
.pc-search-select{
    background-color: #f2f2f2 !important;
    border-color: transparent !important;
    box-shadow: none !important;
    @apply w-full text-black text-xl p-2 outline-none bg-lightgray rounded-md;
}

.pc-search-select:hover{
    box-shadow: none !important;
    border-color: transparent !important;
}

/* .pc-search-select #react-select-2-placeholder,
.pc-search-select #react-select-3-placeholder{
    color: hsl(0, 0%, 50%) !important;
} */

.icon-search{
    @apply absolute top-5 right-3;
}

.pc-courses-list-wrapper.pc-my-courses-list-wrapper{
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 my-11 ;
}
.pc-courses-list-wrapper{
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 my-11 ;
}
.pc-courses-list-wrapper .h-pc-items-list{
 @apply bg-lightgray;
}


/* pagenations  */
.p-pagenation{
    @apply mt-6;

}
ul.pagination{
    @apply flex justify-center items-center gap-3;
}
ul.pagination li{
    @apply w-10 sm:w-12 h-10 sm:h-12 flex justify-center items-center rounded-full ;
}
ul.pagination li.active , ul.pagination li:hover{
    background-image: linear-gradient(-90deg, #67A2D4 0%, #073565 100%);
}
ul.pagination li a{
   @apply text-18 sm:text-24 !text-black font-semibold sm:w-12 h-10 sm:h-12 flex justify-center items-center;
}
ul.pagination li.active a , ul.pagination li:hover a ,  ul.pagination li a:hover {
    @apply !text-white;
    }
   
    ul.pagination li.active a svg , ul.pagination li:hover a svg ,  ul.pagination li a:hover svg , ul.pagination li a svg:hover {
        @apply fill-white;
        }