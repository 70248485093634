.hero-section{
    @apply bg-[url('../../images/BG-1.webp')] bg-[#EAF0F2] pt-16 bg-top bg-cover bg-no-repeat;
}

.hero-section::before{
    @apply bg-[#00366450] content-[''] absolute inset-0 ;
}

.hero-section-wrapper{
@apply grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 pb-8 items-center;
}


.hero-title{
    @apply text-40 sm:text-48 md:text-[60px] text-white font-semibold mb-6 leading-[1] font-Spartan;
}
.hero-title span{
    @apply text-gold;
}
.hero-contains{
    @apply text-white font-normal font-Spartan mb-8 text-xl sm:text-28 ;
}
.hero-right-bg{
    @apply ml-10;
}
.hero-right-bg img{
    @apply bg-cover w-full h-full ;
}
.hero-instructor-title{
    @apply text-black font-medium text-32;
}
.total-instactors{
    @apply text-black text-18 font-medium;
}
.total-instactors span{
    @apply text-gold;
}
.hero-instructor-wrap{
     @apply flex ;
}
.hero-instructor{
    @apply  bg-white rounded-lg px-8 py-5 w-fit ;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,.1);
}
.hero-intructor-img{
    @apply w-full bg-cover h-full;
}
.hero-right{
    @apply relative;
}
.hero-instructor{
    @apply hidden md:block absolute bottom-10 left-[-60px];
} 


/* corse item contains  */
.hero-corse-list{
    @apply bg-transparent grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-4 md:justify-start;
    background-color: transparent;
    background-image: linear-gradient(-90deg, #67A2D4 0%, #073565 100%);
}
.hero-corse-item{
    @apply py-8 border-r-0 md:border-r border-r-[#FFFFFF26];
}
.hero-corse-item:nth-of-type(2n){
    @apply border-r-0 lg:border-r;
}
.hero-corse-item:last-child{
    @apply border-r-0;
}
.hero-corse-item-wrapper{
    @apply flex md:w-3/4 w-full mx-auto items-center justify-start md:justify-start;
}
.hero-corse-item-wrapper p{
    @apply text-white font-medium text-xl;
}
.hero-corse-icon{
    @apply w-[80px] min-w-[80px] h-[80px] bg-[#FFFFFF1A] rounded-full flex justify-center items-center; 
}