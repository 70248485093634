.no-rc-found{
    @apply mx-auto;
  }
  .no-rc-found{
    @apply w-full text-center;
  }
  .no-rc_img{
    @apply  w-full max-w-[300px] object-cover mx-auto;
  }
  .no-rc-title{
    @apply text-18  uppercase;
  }