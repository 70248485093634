.cart-wrapper {
  @apply flex justify-between  flex-col md:flex-row;
}
.cart-left {
  @apply w-full md:w-[60%];
}

.cart-list {
  @apply flex justify-between gap-4 sm:gap-7 border-b border-b-[#1212124D] border-dashed pb-5 mb-5 h-fit flex-col sm:flex-row;
}
.cart-subtotal {
  @apply w-full md:w-[35%];
}

.cart-subtotal .card_{
  @apply relative md:sticky top-0 md:top-32;
}

.cart-list-dec {
  @apply flex gap-4;
}
.cart-list_img {
  @apply w-20 sm:w-32 h-auto sm:h-32 object-contain;
}
.cart-products {
  @apply flex gap-2 sm:gap-12 flex-col sm:flex-row;
}

.quty-delet-wrapper {
  @apply flex gap-1 sm:gap-10 justify-between sm:justify-start;
}
.cart-delet {
  @apply flex flex-col justify-end items-end;
}
.cart-prd-title {
  @apply text-black font-bold text-base pb-3 sm:pb-6 uppercase;
}
.cart-price-title {
  @apply pb-0 sm:pb-6 pr-2 sm:pr-0;
}

.cart-list-price-dec {
  @apply text-black font-bold text-base;
}
.cart-delet-btn {
  @apply flex text-[#FB5339] text-14 sm:text-base font-normal items-center gap-1 uppercase;
}
.cart-delet-btn span {
  @apply text-24 sm:text-[2.5rem] rotate-45 font-normal;
}

.quty-wrapper {
  @apply flex gap-1 justify-between;
}
.quty-item {
  @apply text-18 text-black font-bold h-7 w-7 flex justify-center items-center;
}
.quty-minus {
  @apply text-[1.8rem] pb-1;
}
.quty-plus {
  @apply text-[1.3rem];
}
.cart-tax-wrapper {
  @apply flex justify-between gap-4 items-center border-b border-b-[#1212124D] border-dashed pb-4  mb-6;
}
.cart-taxt-price {
  @apply text-14 text-black font-bold;
}
.cart-total-wrapper {
  @apply flex justify-between gap-4 items-center pb-4 mb-4;
}
.cart-total {
  @apply text-24 text-black font-bold;
}
.cart-checkout-btn {
  @apply w-full mb-4 flex justify-center items-center;
}
.cart-coupon-wrapper {
  @apply flex justify-between gap-3 items-end border-b border-b-[#1212124D] border-dashed pb-6  mb-6;
}
.cart-coupon-name {
  @apply text-14 font-normal w-full block border-b border-b-black py-2;
}
.btn-coupon {
  @apply whitespace-nowrap;
}

.cart-price-wrap {
  @apply flex flex-row sm:flex-col;
}

.order-placed-title.cart-empty {
  @apply text-pink;
}

.order-placed-box {
  @apply px-4 sm:px-8 py-4 sm:py-20 w-full sm:w-[60%] mx-auto border border-lightpink rounded-xl text-center;
}
