.popular-corse-section{
    @apply bg-lightgray py-16 md:py-20 lg:py-24;
}
.h-pc-wrapper{
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 mt-14;
}

.popular-title{
    @apply text-center mb-4;
}
.h-pc-items-list{
    @apply relative bg-white rounded-lg;
}
.h-pc-week{
    @apply inline-flex absolute right-5 top-5  gap-1 items-center py-2 px-2 rounded-md bg-gold text-white text-14 font-normal;
}
.h-pc-item-img{
    @apply h-[200px];
}
.h-pc-item-img img{
    @apply w-full object-cover h-full rounded-tl-lg rounded-tr-lg ;
}
.h-pc-brand-name{
    @apply text-teal text-base rounded-md py-1 px-3 bg-[#0033662e] mb-3 inline-block;
}
.h-pc-item-details{
    @apply px-6 py-10;
}
.h-pc-item-title{
    @apply text-18 font-semibold font-Spartan line-clamp-2 mb-3;
}
.h-pc-raiting{
    @apply flex gap-4 items-center;
}
.h-pc-raitting-name{
    @apply text-black text-14 font-Spartan font-medium;
}
.h-pc-raitting-items{
    @apply flex gap-1 items-center;
}
.h-pc-price{
    @apply my-3 block text-[#073565] text-base font-semibold;
}
.h-pc-lesson-wrap{
    @apply flex justify-between items-center  mt-4; 
}
.h-pc-lesson-item{
    @apply flex justify-start w-full gap-2 border-r border-[#a7a7a7] items-center text-14 font-medium text-[#a7a7a7];
}
.h-pc-lesson-item:last-child{
    @apply border-r-0 justify-end;
}
.h-pc-items{
@apply relative;
}
.h-pc-item-hover{
    @apply pt-4 px-6 pb-12 bg-teal absolute inset-0 hidden rounded-md;
}
.h-pc-items:hover .h-pc-item-hover{
@apply block;
}
.h-pc-wishlist{
    @apply h-12 w-12 ml-auto  rounded-full bg-[#eef6fe63] hover:bg-red-500 cursor-pointer flex items-center justify-center;
}
.h-pc-item-title_white{
    @apply text-white;
}
.h-pc-brand-name_white{
    @apply bg-[#7490AA] text-teal;
}
.h-pc-item-hover .h-pc-raitting-name{
    @apply text-white;
}
.h-pc-price_white{
    @apply text-white;
}
.h-pc-hover-contains{
    @apply text-white text-base font-medium line-clamp-6 mb-3;
}
.h-pc-item-hover .h-pc-lesson-item{
    @apply text-white;
}

.h-pc-explore-btn{
    @apply mt-12;
}
.explore-btn{
    @apply text-white w-fit font-medium text-base flex gap-3 items-center px-4 py-2 rounded-md bg-navyblue;
}

.explore-btn:hover{
    background-image: linear-gradient(-90deg, #67A2D4 0%, #073565 100%) ;
}

.more-corse-btn{
    @apply mt-14 text-center;
}