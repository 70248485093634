.testimonial-wrapper{
    @apply grid grid-cols-1 gap-6 lg:grid-cols-3 items-start ;
}
.testimonial-items:last-child{
    @apply col-span-1 lg:col-span-2 ;
}
.testimonial-btn{
    @apply mt-6;
}

.testimonial-slider-item{
    @apply bg-white py-4 sm:py-16 px-4 sm:px-10 rounded-lg relative;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1);
}
.testimonial-slider-item::before{
    @apply content-[''] absolute right-0 top-0 w-[100px] lg:w-[125px] h-[100px] lg:h-[125px] bg-no-repeat bg-cover  bg-[url('../../images/testimonial-1-shape-1.png')] ;
}
.tst-user-img{
    @apply rounded-full w-20 h-20 relative mb-6;
}
.tst-user-img img{
    @apply object-cover rounded-full w-full h-full;
}
.cotate-icon-wrapper{
    @apply bg-white absolute right-[-7px] bottom-0 rounded-full  ;
}
.cotate-icon{
    @apply bg-teal w-7 h-7 p-1 rounded-full border-2 border-white;
}
.tst-contain{
    @apply text-left mb-6 line-clamp-3;
}
.tst-name-user{
    @apply text-xl font-semibold mt-2;
}
.testimonial-slider{
    @apply p-3 sm:p-6;
}