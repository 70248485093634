.icon_back {
  @apply bg-[url('../icons/back-arrow.svg')] w-5 h-5;
}

.m-icon {
  @apply inline-block text-[24px] w-6 h-6 bg-no-repeat bg-contain bg-center;
}

.icon-emptycart{
  @apply bg-[url('../icons/empty-cart.svg')] w-[5rem] sm:w-[7rem] h-[5rem] sm:h-[7rem] ;
}


.arrow-sidebar{
  @apply bg-[url('../icons/arrow_right_slider.svg')];
}