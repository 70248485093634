.m-icon{
@apply inline-block w-6 h-6 bg-no-repeat bg-contain bg-center;
}

.h-cart_icon{
  /* @apply bg-[url('../../icons/cart-icon.svg')]; */
}

.btn-icon-arrow{
  @apply bg-[url('../../icons/arrow_right.svg')];
}

.icon-search{
  @apply bg-[url('../../icons/search-icon.svg')];
}



/* social icon  */
.social-intra{
  @apply bg-[url('../../icons/instagram-logo.svg')];
}
.social-fb{
  @apply bg-[url('../../icons/facebook-logo.svg')];
}
.social-twitter{
  @apply bg-[url('../../icons/twitter-logo.svg')];
}
.social-youtube{
  @apply bg-[url('../../icons/youtube-logo.svg')];
}
.social-pinterest{
  @apply bg-[url('../../icons/pinterest-logo.svg')];
}