@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply font-Poppins bg-white text-black text-base tracking-[0.0125rem] scroll-smooth;
  }
  main {
    @apply grow shrink-0;
  }
  footer {
    @apply shrink-0;
  }

  *:is(h1, h2, h3, h4, h5, h6) {
    @apply text-black;
  }
  *:focus-visible {
    @apply outline-black border-none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  html {
    @apply scroll-smooth;
  }
}
::selection {
  @apply bg-teal text-white;
}
strong {
  @apply font-medium;
}

p {
  @apply text-black text-base sm:text-18 font-Spartan font-normal;
}
.body_wrapper {
  @apply min-h-screen flex flex-col items-stretch;
}
.site-container {
  @apply xl:container lg:container md:container sm:container container mx-auto;
}
.section_spacing {
  @apply py-16 md:py-20 lg:py-24;
}
.demo {
  @apply text-48 font-thin;
}
#root {
  @apply font-Spartan text-black text-base tracking-[0.0125rem] min-h-screen flex flex-col items-stretch scroll-smooth;
}
.section-title {
  @apply text-28  md:text-4xl text-black font-Spartan mb-10 text-center font-semibold  relative;
}

.section-title::before {
  @apply content-none sm:content-[''] w-32 h-[0.2rem] bg-navyblue rounded-sm absolute top-12 mx-auto left-0 right-0;
}

.section-title.err::before{
  @apply bg-red-400;
}

.light-title {
  @apply text-white text-[1.6rem] sm:text-[2.3rem] md:text-[4.3rem] uppercase font-Poppins font-medium leading-[1.2];
}
.section-title-contain {
  @apply text-darkgray text-center font-Poppins text-base;
}

.color {
  @apply text-navyblue text-teal text-gold text-lightgray text-darkgray;
}

.secondary-title {
  @apply text-[1.875rem] sm:text-[2.3rem] text-black uppercase font-normal font-semibold whitespace-nowrap;
}

.error {
  @apply mt-16 md:mt-0;
}
.error-wrapper {
  @apply text-center my-10;
}
.error-icon {
  @apply mx-auto;
}
.error-oops {
  @apply font-Collection text-[4rem] sm:text-[4rem] text-teal capitalize mb-8 inline-block font-medium;
}
.error-notfound {
  @apply text-black text-28 sm:text-40 my-4 uppercase font-medium;
}
.errr-dec {
  @apply text-base sm:text-xl max-w-md mx-auto;
}
.error-btn {
  @apply mt-12;
}
.error-try-gain {
  @apply text-pink font-bold text-18 border-b border-b-pink capitalize hover:text-Charcolar hover:border-Charcolar;
}
.notfound-wrapper {
  @apply col-span-3;
}
.notfound-search-contain {
  @apply text-center;
}
.serch-result-text {
  @apply py-10 uppercase font-NewsCycle font-medium;
}

nav ul li a.active{
  @apply bg-[#ffffff] text-teal;
}