.about-spacing{
    @apply pb-16 md:pb-28;
}
.h-aboutus-wrapper{
    @apply grid grid-cols-1 md:grid-cols-2 gap-16 xl:gap-28 items-center ;
}
.h-aboutus-left{
    @apply relative;
}
.h-aboutus-left-img{
    @apply w-full h-full md:h-[420px] rounded-md overflow-hidden;
}
.h-aboutus-left-img img{
    @apply mx-auto rounded-xl bg-inherit;
}
.h-aboutus-small-img{
    @apply w-[220px] h-[270px] absolute top-[-65px] left-96 hidden md:block;
}
.h-aboutus-small-img img{
box-shadow: 0px 40px 70px 0px rgba(26.999999999999993, 20, 78, 0.1);
@apply w-full h-full object-cover;
}
.h-about-title-wrap{
    @apply mb-4 pr-0;
}

.contact-form_{
    box-shadow: 0px 40px 70px 0px rgba(26.999999999999993, 20, 78, 0.1);
    @apply p-10 w-full z-10 bg-white;
}

.h-about-title-wrap .section-title{
@apply text-left mb-3  sm:text-[28px] md:text-5xl   leading-none ;
}
.h-about-title-wrap .section-title::before{
    @apply content-none  ;
    }
.h-about-title-wrap .section-title span{
    @apply text-[#67A2D4] sm:text-[28px] md:text-5xl ;
}
.h-about-title-wrap .section-title-contain{
    @apply text-left leading-7 ;
}
.h-about-title{
    @apply mb-4 text-gray-400 font-[600] ;
}
.h-about-item-list{
    @apply text-black  text-18 relative pl-5 font-medium font-Spartan mb-1;
}
.h-about-item-list::before{
    @apply w-2 h-2 bg-navyblue rounded-full content-[''] absolute left-0 top-2;
}
.about-award{
    @apply bg-white py-3 px-5 rounded-md  absolute bottom-[-2rem] right-[40px]   ;
    box-shadow: 0 40px 70px rgba(27,20,78,.1);
}
.about-award-title{
    @apply text-xl text-black font-medium;
}
.about-award-title span{
    @apply block text-red-500 ;
}
.award-icon{
    @apply h-14 w-14 bg-gray-200 rounded-full flex items-center justify-center;
}
.about-award-price{
    @apply hidden md:flex gap-4 items-center;
}

.h-contact-title-wrap{
    @apply mb-4 pr-9 ;
}
.h-contact-title-wrap .section-title{
    @apply text-left mb-3 text-4xl  leading-tight ;
}
.section-address{
    @apply text-left mb-2 text-2xl  leading-tight ;
}
.h-contact-title{
    @apply mb-2 text-gray-400  text-left leading-6 ;
 }
.h-contact-title-wrap .section-title::before{
    @apply content-none ;
 }
.h-contact-title-wrap .section-title span{
    @apply text-red-500 text-4xl;
}
.h-contact-title-wrap .section-title-contain{
    @apply text-left leading-7 ;
}
.contact-input {
    @apply outline-none border-b-2 border-solid focus:border-emerald-400 transition-all;
  }
  .contact-form {
    @apply max-w-md mx-auto;
  }
  .contact-form-section {
    @apply bg-white col-span-2 py-14 mt-0;
  }
  .contact-form-head {
    @apply text-3xl font-medium pt-2;
  }
  .contact-form-content {
    @apply h-contact-title pt-2;
  }
  .contact-div {
    @apply flex flex-col py-4;
  }
  .contact-button {
    @apply   mt-4 py-3;
  }
  .get-section {
    @apply  overflow-visible mt-56;
  }
  .cart-section {
    @apply flex items-center rounded-lg  bg-emerald-500 text-stone-200 text-xl mt-4 mx-20 h-14;
  }
  .about-grid-div {
    @apply max-w-sm rounded overflow-hidden shadow-md text-center items-center;
  }
 .aboutus-image {
    @apply w-20 h-20 rounded-full mr-4;
 }
 .aboutus-left-div {
    @apply font-Spartan text-left  text-xl my-2;
 }
 .h-cart-item-list{
    @apply text-black  text-lg relative pl-5 font-medium font-Spartan mb-1;
}

.cart-text-input {
    @apply focus:outline-0  gap-4 ml-3 mt-2 px-5 py-2 font-Spartan font-medium border-0 text-18 ;
}

.p-cart-title{
    @apply text-black text-5xl font-semibold mb-4;
}
.checkout-input {
    @apply  w-full p-3  outline-emerald-300 text-gray-900 border rounded-lg bg-gray-50 text-base focus:ring-emerald-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
}
.checkbox-label {
    @apply  mb-2 text-lg text-black font-medium lg:text-black
}
.checkout-heading {
    @apply relative text-2xl font-medium text-black lg:text-black sm:text-3xl border-b;
}
