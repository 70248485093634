.logo {
    /* padding-top: 10px; */
}

.css-10hburv-MuiTypography-root {
    font-family: Karla !important;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.8rem;
}

.left_h .MuiButtonBase-root.MuiIconButton-root {
    color: white !important;
    margin: 0;
}

.MuiListItemIcon-root.icon_,
.MuiList-root a {
    color: white;
}

a.active>li .MuiListItemIcon-root.icon_,
a.active>li {
    color: #282c34;
    background-color: #FFF;
}

.copy-right {
    color: var(--primary);
}