.p-footer{
  @apply bg-darkgray py-11 sm:py-[4.375rem];
}
.footer-wrapper{
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 justify-between pb-4 sm:pb-12 text-left;
}
.footer-menu_link{
  @apply text-white font-light tracking-[1px] pb-4 sm:pb-6 block hover:text-lightgray uppercase text-14;
}
.footer-contact_wrapper{
  @apply text-white tracking-[1px] text-base font-light flex items-center gap-3 justify-center sm:justify-start;
}
.f-social_wrapper{
  @apply flex items-center gap-3 mb-5 mt-8 sm:mt-9;
}
.footer_logo-link{
  @apply hidden sm:block ;
}
.footer_logo-link_mobile{
  @apply flex items-center justify-center sm:hidden ;
}
.sb_footer-logo{
  @apply w-full max-w-[3.125rem] sm:max-w-[12.5rem] md:max-w-[15.625rem];
}
.footer-wrapper .footer-col_logo{
  @apply order-1 md:order-none mx-auto sm:mx-0 mb-8 sm:mb-0 py-4;
}
.footer-wrapper .footer-menu-one{
  @apply order-3 md:order-none ml-0 py-4;
}
.footer-wrapper .footer-menu-two{
  @apply order-4 md:order-none ml-0 py-4;
}
.footer-wrapper .footer-col_contact{
  @apply order-4 md:order-none sm:mx-0 mt-5 sm:mt-0 py-4;
}
.sb_footer-logo_company{
  @apply w-8 h-8 bg-no-repeat object-cover;
}

/* footer copyright css  */
.footer-bottom_wrapper{
  @apply flex justify-center gap-4 items-center border-t border-t-borderGray pt-6 sm:pt-10 flex-col sm:flex-row ;
}
.footer-botom_item{
  @apply text-white font-normal uppercase text-14 tracking-[1px] ;
}
.footer-botom_item:last-child{
@apply grow sm:grow-0 flex sm:block justify-center;
}
.footer-comapny_logo{
  @apply !flex items-center gap-2 justify-start;
}
.footer-logo .m_logo-link , .footer-logo .m_logo-link span{
  @apply text-white;
}


.footer-contains{
  @apply text-white font-normal lg:mt-4 text-18 sm:mr-8;
}

.footer-title{
  @apply font-semibold font-Poppins text-white mb-5;
}
.footer-contact-wrap{
  @apply flex gap-1 items-center  mb-4 ;
}
.footer-contact-wrap span{
  @apply font-medium text-white text-base;
}