.registration-wrapper {
  @apply grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12;
}
.reg_img {
  @apply w-full aspect-square;
}
.regisration-col_wrapper {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6;
}
.regisration-col-select_wrapper {
  @apply grid grid-cols-1 md:grid-cols-3 gap-0 sm:gap-6;
}
.form-feild-title {
  @apply font-bold text-18 text-black mb-4;
}
.radio_box {
  @apply flex justify-between items-start md:items-center flex-col md:flex-row;
}
.required-star {
  @apply text-red-500 font-normal text-[1.2rem] -ml-[0.0625rem];
}
