.h-entroled{
    @apply bg-white max-w-full sm:max-w-[785px] mx-auto p-6 sm:p-20 rounded-md relative;
    box-shadow: 0px 30px 70px 0px rgba(15.99999999999999, 12.000000000000002, 47, 0.08);
}
.h-entroled::before{
    @apply content-none sm:content-[''] absolute  left-[-50px] top-[-50px] bg-[url('../../images/shape-02-round.png')] bg-no-repeat h-[150px] w-[150px] bg-cover 
   ;
   animation: spin-slow 11s linear infinite;
}
@keyframes spin-slow {
    0% {
   
        transform: rotate(0deg)
    }

    100% {
    
        transform: rotate(-360deg)
    }
}

.h-entroled-wrapper{
    @apply grid grid-cols-1 sm:grid-cols-2 text-center ;
}
.h-entroled-items{
    @apply border-0 sm:border-r border-r-[#E5E5E5] p-4 sm:p-12 border-b-0 sm:border-b border-b-[#E5E5E5];
}
.h-entroled-count{
@apply font-bold text-32 sm:text-[40px];
}
.h-entroled-text{
    @apply text-base text-black font-medium;
}
.h-entroled-items:first-child .h-entroled-count{
    @apply text-[#1AB69D];
}
.h-entroled-items:nth-child(2) .h-entroled-count{
    @apply text-[#EE4A62];
}
.h-entroled-items:nth-child(3) .h-entroled-count{
    @apply text-[#F8941F];
}
.h-entroled-items:last-child .h-entroled-count{
    @apply text-[#8E56FF];
}
.h-entroled-items:nth-child(2n){
    @apply border-r-0;
}
.h-entroled-items:nth-child(3n) , .h-entroled-items:nth-child(4n){
    @apply border-b-0;
}
.section-h-enroled {
    @apply mt-[-310px];
}

/* call to action in home page  */

.call-to-action-wrapper{
    @apply mx-auto max-w-full sm:max-w-[1000px] relative rounded-lg  p-10;
    background-image: linear-gradient(-90deg, #67A2D4 0%, #073565 100%);
}
.call-to-action-wrapper::before{
    @apply content-[''] absolute inset-0  bg-[url('../../images/cta-bg-imgae-07.png')] bg-no-repeat bg-cover w-full h-full ;
}
.call-to-action-wrapper{
    @apply flex justify-start sm:justify-center items-center gap-4 sm:gap-12 flex-col lg:flex-row;
}

.cta-items{
    @apply lg:w-[40%];
}

.cta-items h5{
 @apply text-28 font-normal text-white mb-2;
}
.cta-items-mail{
    @apply text-32 font-semibold text-white;
}
.cta-items:first-child{
    @apply text-center lg:text-right;
}
.cta-or-ditem{
    @apply rounded-full  flex justify-center  items-center w-20 h-20 bg-white border-4 border-[#9fc1e363];
}
.cta-or-title{
@apply text-teal font-semibold text-40;
}
.cta-items{
    @apply z-[9] relative;
}