.main-header {
  @apply sticky  top-0 z-[999];
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
}
.m-header {
  @apply bg-white;
}
.sb_logo {
  @apply w-full max-w-[3.125rem]  md:max-w-[18.75rem];
}
.nav_wrapper {
  @apply flex justify-between items-center py-4 md:py-3;
}
.h-account_link {
  @apply flex items-center gap-2;
}

.h-account_title {
  @apply text-black text-14 font-normal hidden md:block;
}

.h-account_link:hover .h-account_title {
  @apply text-navyblue;
}

.h-account_wrapper,
.h-cart_wrapper {
  @apply flex items-center gap-5 md:gap-8 py-3;
}
.h-cart_wrapper {
  @apply order-4 md:order-none mr-4 md:mr-0 relative pl-0 md:pl-14;
}

.h-cart-number {
  @apply bg-navyblue text-white text-14 font-normal absolute right-[-10px] top-[1px] p-[2px] w-5 h-5 rounded-full flex justify-center items-center;
}
.h-account_wrapper {
  @apply md:mr-0 mr-0 order-1 md:order-none p-0 ml-[0.2rem] md:ml-0 relative;
}
.h-menu_wrapper {
  @apply flex justify-start md:justify-center items-start md:items-center gap-0 md:gap-6 lg:gap-14  flex-col md:flex-row bg-white fixed md:static left-0 right-0 bottom-0  top-[5.15rem] px-5 inset-0 z-10 md:z-auto md:inset-auto -translate-x-full md:transform-none transition-all duration-200 ease-in-out overflow-y-auto md:overflow-y-visible py-10 md:pt-0   border-t border-t-borderGray md:border-none pb-0;
}
.nav-list_wrapper.active .h-menu_wrapper {
  @apply translate-x-0 md:transition-none bg-white;
}

.h-menu_link {
  @apply text-black font-medium text-xl hover:text-teal whitespace-nowrap capitalize font-Spartan;
}

/* toggle css  */

.m_toggle-wrapper {
  @apply order-3 md:order-none block md:hidden mr-3;
}
.m_toggle {
  flex-flow: row wrap;
  @apply w-[1.4rem] h-6 relative flex cursor-pointer items-center order-3;
}
.m_toggle-items {
  @apply w-full h-[0.125rem] relative bg-black duration-[0.5s] transition-all;
}
.m_toggle.active .m_toggle-items:nth-child(2) {
  @apply opacity-0;
}
.m_toggle.active .m_toggle-items:nth-child(1) {
  @apply -rotate-45 top-2;
}
.m_toggle.active .m_toggle-items:nth-child(3) {
  @apply rotate-45 -top-2;
}

.dashtop-search {
  @apply hidden md:block;
}
.mobile-search {
  @apply flex md:hidden mx-2;
}

/* order set in mobile  */
.m_logo {
  @apply order-2 md:order-none flex-grow;
}

.m_logo-link {
  @apply md:flex  items-center text-teal text-ellipsis font-semibold text-18 sm:text-28 font-Spartan;
}

.m_logo-link span {
  @apply text-navyblue font-Poppins text-base sm:text-24;
}

.h-menu_list {
  @apply w-full md:w-auto;
}
.h-menu_list-mobile {
  @apply block md:hidden;
}
.h-menu_link {
  @apply block py-4 border-b-[#dfdfdfeb]  w-full md:w-auto;
}

.nav-list_wrapper:has(.active) body {
  @apply overflow-y-hidden;
}

.header-newsletter_title {
  @apply text-[1.25rem] font-normal font-Spartan mb-4;
}
.header-newsletter {
  @apply my-8;
}

/* social media in header  */
.header-social_media {
  @apply flex items-center gap-3;
}

/* sub menu css  */

.h-sub_menu {
  @apply relative;
}
.h-sub_menu:hover .h-sub-menu_link {
  @apply text-navyblue cursor-pointer;
}
.h-sub_menu-list {
  @apply static md:absolute right-[-1.25rem] w-full md:w-[15rem] bg-white top-0 transition-all duration-[0.5s] z-[99] p-3 opacity-100 md:opacity-0 pointer-events-none shadow-none md:shadow-submenu hidden md:block;
}
.h-sub_menu:hover .h-sub_menu-list {
  @apply opacity-100 pointer-events-auto top-7 block;
}
.h-menu_link.h-sub-menu_link:after {
  @apply content-[''] w-2 h-2 border-l-2 border-black border-b-2 inline-block ml-[9px] -rotate-45 relative -top-[0.2rem] cursor-pointer opacity-60;
}

.h-sub_menu-list:before {
  @apply content-none md:content-[''] h-6 bg-transparent -mt-7 w-full absolute;
}

.h-sub_menu-item {
  @apply py-2;
}

.h-sub_menu-item {
  @apply hover:text-navyblue;
}

/* edit profile  */

.h-account-submenu {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  @apply px-5 py-2 bg-white w-[16rem] sm:w-80 absolute right-[-12.25rem] sm:right-[-18.25rem] md:right-[-0.25rem] invisible z-[9]  top-0 transition-all duration-[0.5s] opacity-0 pointer-events-none;
}
.h-subaccount-items {
  @apply py-4 border-b;
}
.h-subaccount-name {
  @apply text-navyblue font-bold text-base block;
}
.h-subaccount-item {
  @apply text-black text-18 py-1 font-normal hover:text-navyblue block;
}
.h-subaccount-items:last-child {
  @apply border-none;
}
.h-Logout-btn {
  @apply border border-[#7171716e] px-3 py-1 rounded-lg text-18 text-black hover:bg-teal hover:border-transparent;
}
.h-account_wrapper:hover .h-account-submenu {
  @apply visible top-[3.3rem] sm:top-[2.375rem] opacity-100 pointer-events-auto;
}
.h-account-submenu:before {
  @apply content-[''] h-6 bg-transparent -mt-7 w-full absolute;
}
.menu-item-wrapper {
  @apply flex gap-0 md:gap-12 items-center order-2 md:order-none mr-2 sm:mr-6 md:mr-0;
}

.MuiBadge-badge.MuiBadge-colorDanger{
  background-color: red;
  @apply text-white;
}