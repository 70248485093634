.ip_input {
  @apply border-b border-b-[#121212] w-full py-3 outline-transparent focus-visible:border-solid focus:border-pink focus-visible:border-pink appearance-none text-base bg-transparent;
}

.ip_form-control {
  @apply w-full relative mb-5 sm:mb-9;
}

.ip_lable {
  @apply text-black  font-normal text-base font-Spartan absolute -translate-y-1/2 top-1/2 left-0 bg-transparent px-1 pointer-events-none;
  transition: all 0.3s ease-in-out;
  transform-origin: 0 0;
}
.ip_input {
  @apply placeholder:text-black placeholder:text-base;
}

.ip_input:focus {
  @apply outline-1;
}

.ip_input:focus ~ .ip_lable,
.ip_form-control .ip_input:not(:placeholder-shown) + .ip_lable {
  @apply -top-3 -translate-y-0 -translate-x-1 leading-none;
}

.input-wrap .ip_form-control {
  @apply mb-0;
}

.input-wrap {
  @apply mb-6 sm:mb-6;
}

.ip-checkbox__wrapper {
  @apply block mb-3;
}

.ip-checkbox__input {
  @apply p-0 hidden cursor-pointer mb-0;
}

.ip-checkbox__label {
  @apply relative cursor-pointer inline-flex gap-[0.125rem] items-center;
}

.ip-checkbox__label:before {
  @apply border w-5 h-5 border-black bg-transparent p-2 inline  -block relative align-middle cursor-pointer mr-1 rounded content-[''];
}

.ip-checkbox__wrapper .ip-checkbox__input:checked + .ip-checkbox__label:before {
  @apply border border-black;
}
.ip-checkbox__input:checked ~ .ip-checkbox__label {
  @apply text-black opacity-100 font-medium;
}

.ip-checkbox__wrapper .ip-checkbox__input:checked + .ip-checkbox__label:after {
  @apply block content-[''] absolute top-[0.37rem] left-[0.4375rem] w-[0.375rem] h-3 border border-black rotate-45 border-l-0 border-t-0 border-b-2 border-r-2;
}
/* Colours FIlter Css */
.ip_checkbox-color {
  @apply w-8 h-8 rounded border border-[#e4e5e7];
}

/* select css */
.select-wrapper {
  @apply mb-5 sm:mb-9 relative;
}
.select-label {
  @apply text-base font-normal  inline-block mb-1;
}

/* .select-input {
  @apply block w-full cursor-pointer appearance-none border-b border-b-[#121212] py-3 outline-transparent  bg-[url('/icon/select-down.svg')] bg-[right_center] bg-origin-content text-black outline-offset-2 bg-no-repeat mr-6 pr-3.5 text-base focus:border-pink focus-visible:border-pink;
} */

.select-wrapper .css-13cymwt-control {
  @apply w-full cursor-pointer border-0 rounded-none  border-b border-b-[#121212]  text-base focus:border-pink focus-visible:border-pink shadow-none;
}
.select-wrapper .css-t3ipsp-control:hover,
.css-t3ipsp-control {
  @apply border-x-0 border-t-0 border-b border-b-black rounded-none;
}
.select-wrapper .css-t3ipsp-control {
  @apply border-x-0 border-t-0 rounded-none shadow-none border-b-black;
}
.css-t3ipsp-control:hover {
  @apply border-b-black;
}
.select-wrapper .css-t3ipsp-control {
  @apply shadow-none;
}
.select-wrapper .css-qbdosj-Input {
  @apply py-2;
}
.select-option {
  @apply text-base text-black capitalize font-normal;
}
.react-select-3-placeholder::placeholder {
  /* @apply text-black; */
}
.css-1jqq78o-placeholder {
  /* @apply !text-black; */
}
/* for radio  button */
.form-c-radio,
.form-c-radio span {
  @apply font-normal text-base text-black capitalize inline-block mb-0;
}
.input-wrapper-radio {
  @apply mb-4 md:mb-9 flex items-center gap-2 focus:outline-none;
}

.input-wrapper-radio input[type="radio"] + label {
  @apply text-[#5a5a5a];
}

.input-wrapper-radio input[type="radio"]:checked + label {
  @apply text-black;
}
.radioClass {
  @apply accent-black w-5 h-5;
}

.input-error-msg {
  @apply text-red-500 text-14 mt-1 text-left;
}
.input-text-danger {
  @apply text-red-600;
}
.required {
  @apply relative;
}
.required:after {
  @apply absolute text-[#dc3545] content-['*'] text-18 leading-[1] -top-[0.1875rem];
}

/* tags input css  */

.rti--container {
  @apply !border-t-0 !border-x-0 !rounded-none !border-b !border-b-[#121212] !w-full !py-3 outline-transparent focus-visible:border-solid focus:border-pink focus-visible:border-pink appearance-none !text-base !bg-transparent;
  --rti-main: white !important;
}
.rti--input {
  @apply w-full;
}

/* color picker  */

.color-input-wrapper {
  @apply flex items-center space-x-2 border-b border-b-black;
}
.color-input {
  @apply h-8 w-8;
}
.color-input-value {
  @apply p-2 text-sm focus:outline-none;
}
.color-picker-wrapper {
  @apply grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 mb-5 sm:mb-9;
}


.react-tel-input .form-control {
  @apply !border-t-0 !border-x-0 !rounded-none  !border-b !border-b-[#121212] !w-full !py-3 !outline-transparent focus-visible:!border-solid focus:!border-pink focus-visible:!border-pink !appearance-none !text-base !bg-transparent !h-[3rem];
}
.react-tel-input .flag-dropdown {
  @apply !rounded-none !bg-transparent !outline-transparent !border-none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  /* Reset any other unwanted styles */
}
textarea.ip_input {
  @apply mt-3;
}

.phone-require-vendor .selected-flag:after {
  content: " *";
  position: absolute;
  right: 7px;
  top: 7px;
  color: red;
}
