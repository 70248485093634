.section-title-wrap{
    @apply mb-10;
}

.category-wrapper{
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-7;
}
.category-items{
    @apply flex items-center gap-6 bg-[#67a1d424] hover:bg-navyblue p-6 text-black hover:text-white rounded-md;
}

 .category-wrapper .category-items:nth-child(6){
    @apply bg-[#EE4A6212] hover:bg-[#EE4A62];
}
.category-wrapper .category-items:nth-child(2) {
    @apply bg-[#EE4A6212] hover:bg-[#EE4A62];
}
.category-wrapper .category-items:nth-child(4) {
    @apply bg-[#F8B81F12] hover:bg-[#F8B81F];
}
.category-wrapper .category-items:nth-child(8){
    @apply bg-[#F8B81F12] hover:bg-[#F8B81F];
}
.category-wrapper .category-items:nth-child(5) {
    @apply bg-[#8E56FF12] hover:bg-[#8E56FF];
}
 .category-wrapper .category-items:nth-child(7){
    @apply bg-[#8E56FF12] hover:bg-[#8E56FF];
}
.category-item-title{
    @apply text-black text-xl font-medium font-Spartan;
}

.category-items:hover .category-item-title{
    @apply text-white;
}