.pc-details-banner {
    @apply  py-5  w-full h-[250px] bg-no-repeat bg-inherit bg-center text-center flex justify-center items-center flex-col relative bg-cover ;
}

.pc-details-banner::before{
    @apply bg-[#00366450] content-[''] absolute inset-0 ;
}
.pc-details-banner .p-bradcom-item{
    @apply text-white ;
}
.pc-details-banner .p-couse-bradcom-wrapper img{
    filter: invert(1);
} 
.pc-details-banner .p-couse-bradcom-wrapper{
  @apply  z-[9] relative;
}
.pc-details-title{
    @apply text-white  text-32 lg:px-[0px] sm:px-[40px] md:px-[0px]  sm:text-[60px] font-Spartan text-center mb-6 font-semibold z-[9] sm:px-0 md:px-10 relative ;
    line-height: 1.0;   
}

.pc-details-wrapper{
    @apply grid grid-cols-1 xl:grid-cols-3 ;
}
.pc-details-items:first-child{
    @apply col-span-1 xl:col-span-2 mr-0 xl:mr-14;
}

.pc-details-price-section{
    @apply py-16 px-8 bg-white relative top-0 md:sticky md:top-32;
    box-shadow: 0px 10px 50px 0px rgba(26, 46, 85, 0.07);

}
.pc-dp-title{
    @apply font-Spartan font-medium text-black text-24 sm:text-32 mb-6;
}
.pc-dp-item{
    @apply py-3 border-b border-b-[#e5e5e5] flex justify-between items-center;
}
.pc-dp-item:last-child{
@apply border-none;
}
.pc-dp-item-title{
    @apply text-black font-Spartan text-xl sm:text-24 font-normal;
}
.pc-dp-price{
    @apply font-bold text-[22px] text-right text-teal;
}
.pc-dp-item-contain{
    @apply text-black text-right text-[22px] font-medium;
}
.btn-bynow .m-btn{
@apply w-full text-center text-18 sm:text-24 py-3 sm:py-3 justify-center;
}
.btn-bynow{
    @apply my-8;
}

.pc-dl-title{
    @apply text-black mb-10 font-semibold font-Spartan text-28 sm:text-48;
}
.pc-dl-img{
    @apply w-full h-full  sm:h-[450px] rounded-lg relative;
}
.pc-dl-img img{
    @apply w-full h-full object-cover rounded-lg ;
}
/* .pc-dl-img::before{
    @apply bg-[#00000099] rounded-lg content-[''] absolute inset-0;
    
} */

.course-details-slider .h-pc-items-list{
    @apply bg-lightgray;
   }
   

/* tabs  */

.pc-details-tab{
    @apply my-14;
}
.pcd-tab-items{
    @apply flex justify-center gap-1 flex-wrap sm:gap-1 items-center border-b border-b-[#e5e5e5] ; 
}
.pcd-tab-items-list{
    @apply py-2 px-4  cursor-pointer text-[#808080] text-xl sm:text-24 font-normal font-Spartan border-b-2 sm:border-b-4 border-b-transparent;
}
.pcd-tab-items-list.active{
@apply text-teal  border-b-teal
}
.pcd-tabs-details{
    @apply pt-14;
}
.pc-overviw-contains{
    @apply  text-xl  font-medium text-[#808080] mb-4;
}
.pc-review-wrapper{
    @apply py-8 border-b border-b-[#e5e5e5] flex  gap-8;
}
.pc-review-img{
@apply h-fit w-[120px] rounded-full;
}
.pc-review-img img{
    @apply h-full w-full object-cover rounded-full;
}
.h-pc-rv-name{
    @apply text-black font-medium text-24 mt-2 font-Spartan;
}
.h-pc-rv-subject{
    @apply text-black font-normal text-24 my-1;
}
.h-pc-review-contains{
    @apply text-18  sm:text-xl  font-medium text-[#808080];

}
.pc-review-wrapper:last-child{
@apply border-none;
}

.accordion{
    @apply flex flex-col gap-8;
}

.accordion .accordion-item{
    border: none;
    box-shadow: 0px 10px 50px 0px rgba(26, 46, 85, 0.07);
}

.accordion-button:focus{
    box-shadow: none;
}

.accordion-button:not(.collapsed) h4{
    @apply text-white;
}
.accordion-button:not(.collapsed){
    @apply bg-teal text-white;

}



