.login-wrapper {
  @apply max-w-full sm:max-w-[700px] mx-auto text-center;
}
.login-btn_wrapper {
  @apply flex justify-between items-center mt-10 mb-4 sm:mb-8 flex-col gap-4 sm:flex-row;
}
.btn-forgotpass {
  @apply text-[14px] font-normal underline text-black;
}
.login-signup {
  @apply text-18 font-normal text-black;
}
.login-signup_link {
  @apply underline font-bold text-xl;
}
