/* DashboardProfile.css */

.user-info-section {
    @apply bg-white rounded-lg shadow-md p-6 mb-8 md:mb-0 md:mr-4 w-full;
  }
  
  .update-password-section {
    @apply bg-white rounded-lg shadow-md p-6 w-full md:w-1/2;
  }
  
  .user-info-heading {
    @apply text-xl font-semibold mb-4;
  }
  
  .input-label {
    @apply block text-gray-700 font-bold mb-2;
  }
  
.input-box {
    @apply  w-full mb-4;
  }
  /* Modal Styles */
.modal-container {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

/* Close Button Styles */
.close-modal-btn {
  /* position: absolute; */
  /* top: 10px; */
  /* right: 10px; */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Overlay Styles */
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 9999; /* Ensure the overlay is on top of everything */
}

  
  /* Add any additional custom classes here */
  