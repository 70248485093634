.profile-title {
    @apply text-32 font-normal text-black border-b border-b-black py-3 mb-10;
  }
  .section-title.title-edit-profile {
    @apply whitespace-normal leading-[1.2];
  }
  .img_preview-wrapper {
    @apply flex mt-4 sm:mt-8 mb-4 sm:mb-8;
  }
  .img_perivew-col .img_preview-wrapper {
    @apply mb-0;
  }
  .checkout-item .img_perivew-col{
    @apply  mb-4 sm:mb-8;
  }
  .submistion-col-wrapper .img_preview-wrapper {
    @apply mb-0;
  }
  .submistion-col-wrapper .img_perivew-col{
    @apply  mb-4 sm:mb-8;
  }
  .img_preview-item {
    @apply relative w-28 sm:w-32 h-28 sm:h-32 mb-4 rounded-full border border-white;
  }
  .img_preview-image {
    @apply w-full h-full object-cover rounded-full;
  }
  .img_preview-btn {
    @apply absolute top-2 right-2 h-8 w-8 flex justify-center items-center bg-white rounded-full;
  }
  .img_preview-lable {
    @apply cursor-pointer w-28 sm:w-32 h-28 sm:h-32 border border-black flex items-center justify-center rounded-full;
  }
  .img_preview-remove-rounded {
    @apply rounded-none;
  }
  .img_preview-remove {
    @apply rotate-45;
  }
  .profile-name-title {
    @apply text-black text-18 sm:text-xl font-normal mb-1 sm:mb-5;
  }
  .edit-grid-3 {
    @apply gap-0 sm:gap-4;
  }
  .checkout-wrapper.profile-edit-wrapper {
    @apply gap-0 sm:gap-12;
  }
  .edit-perofile-btn {
    @apply flex gap-3 sm:gap-6 items-center flex-col sm:flex-row mb-6 mt-12 sm:mt-6;
  }
  .file-lable {
    @apply block mb-4 font-normal text-base capitalize;
  }
  .upload-btn-wrapper {
    @apply relative overflow-hidden inline-flex items-center gap-3;
  }
  .upload-btn-wrapper input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .profile-upload-text {
    @apply text-black font-normal text-18;
  }
  .edit-v-contains .ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    height: 135px;
  }
  
  .regisration-col_wrapper .select-wrapper .css-19bb58m{
    @apply !py-2;
  }