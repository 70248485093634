* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --text: #333333;
  --text-light: #F2F2F2;
  /* --white: #fff;
  --light: #e3f6f5;
  --med: #bae8e8;
  --dark: #2c698d; */
  --primary: #003366;
  --primary-dark: #042b52;
  --secondary: #008080;
  --gold: #FFD700;
  --dark-gray: #58647B;
  /* --home-dark: #3b4536;
  --home-bg: #0a1805; */
}

.App {
  text-align: center;
}

h2.h2 {
  color: var(--primary);
}

body {
  font-family: "Karla";
}

/* a:active, */
.MuiBreadcrumbs-li a {
  text-decoration: none;
  color: var(--primary);
}


.mySpinner {
  color: var(--primary)
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-d {
  background-image: url('/src/admin/assets/bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.errMsg {
  font-size: 14px;
}

.MuiOutlinedInput-root:focus-visible .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
  border-width: 1px !important;
  outline: none !important;
}

label {
  color: var(--primary);
}

.view-user .nav-link:focus, .view-user .nav-link:hover,
.view-user .nav-link {
  color: var(--primary) !important;
}

.view-user .nav-pills .nav-link.active, .view-user .nav-pills .show>.nav-link,
.default-btn.MuiButton-containedPrimary {
  background-color: var(--primary) !important;
  color: var(--text-light) !important;
}

.btn-outline:active,
.btn-outline:hover,
.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--text-light) !important;
}

.btn-outline {
  border-color: var(--primary) !important;
  background-color: transparent !important;
  color: var(--primary) !important;
}

.btn-view {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}


.swal2-styled.swal2-default-outline:focus,
.swal2-styled.swal2-default-outline {
  box-shadow: none;
}

.primary-btn:hover {
  background-color: var(--primary-dark);
}

.default-btn.MuiButton-containedPrimary:hover {
  background-color: var(--primary-dark);
}

.MuiInputBase-root.MuiOutlinedInput-root {
  /* outline: none !important; */
}

/* .MuiLoadingButton-root.default-btn {
  font-size: 1rem;
  border: transparent;
  color: #0c263a;
  font-family: Open Sans, sans-serif;
  padding: 0.75rem 1rem;
  text-decoration: none;
  transition: all .3s ease-out;
  text-transform: capitalize;
  line-height: 1;
  border-radius: 8px;
} */

#menu-course_id,
#menu-membership_id,
#menu-user_id,
#menu- {
  z-index: 9999;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #b98d58 !important;
}

.login_paper.MuiPaper-elevation {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #f1f6fa;
}

main.admin_main {
  flex-shrink: 1;
}

.btn-secondary {
  background-color: var(--text) !important;
  border-color: var(--text) !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




/* dashboard */
.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}


.card-paper.MuiPaper-elevation {
  width: 100%;
  background-color: rgb(46, 45, 61);
}

.cards-grid .cards {
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.cards-grid .cards span.count {
  font-size: 50px;
  color: #FFF;

}

.cards-grid .cards .icon-d {
  color: #fff;
  font-size: 80px;
}

.cards-grid .cards p,
.cards-grid .cards h2 {
  color: #FFF;
}

.form-check-label,
select,
input[type='radio'] {
  cursor: pointer;
}

.btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") !important;
}

.filter-select {
  width: fit-content;
  cursor: pointer;
}

.MuiAccordionSummary-content {
  align-items: center;
  justify-content: space-between;
}

/* ._dashboard-card {
  width: 23%;
} */


.course-details-s .left-d1 {
  width: 25%;
}

.course-details-s .right-d1 {
  width: 75%;
}

@media screen and (max-width: 991.98px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);

  }

  .course-details-s .right-d1,
  .course-details-s .left-d1 {
    width: 100%;
  }


  /* ._dashboard-card {
    width: 48%;
  } */
}

@media screen and (max-width: 767.98px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);

  }


  /* ._dashboard-card {
    width: 100%;
  } */
}